import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { IShopConfig, SHOP_CONFIG } from '@x/ecommerce-shop/config/shop.config';

@Component({
  selector: 'x-brand-title',
  templateUrl: './brand-title.component.html',
  styleUrls: ['./brand-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'x-brand-title',
  },
})
export class BrandTitleComponent {
  @Input() title: string = this.shopConfig.meta.titleSuffix;

  constructor(@Inject(SHOP_CONFIG) private shopConfig: IShopConfig) {}
}
