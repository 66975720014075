import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrandEmblemComponent } from './brand-emblem/brand-emblem.component';
import { BrandLogoComponent } from './brand-logo/brand-logo.component';
import { BrandTitleComponent } from './brand-title/brand-title.component';

@NgModule({
  imports: [CommonModule],
  declarations: [BrandTitleComponent, BrandLogoComponent, BrandEmblemComponent],
  exports: [BrandTitleComponent, BrandLogoComponent, BrandEmblemComponent],
})
export class BrandingModule {}
