import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { IShopConfig, SHOP_CONFIG } from '@x/ecommerce-shop/config/shop.config';

@Component({
  selector: 'x-brand-emblem',
  templateUrl: './brand-emblem.component.html',
  styleUrls: ['./brand-emblem.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'x-brand-emblem',
  },
})
export class BrandEmblemComponent {
  svg: { svgPath: string; svgViewBox: string } = this.shopConfig.brand.emblem;

  constructor(@Inject(SHOP_CONFIG) private shopConfig: IShopConfig) {}
}
